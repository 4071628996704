import React from 'react'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'
import { POKER } from '../../components/download-links'
import { graphql } from 'gatsby'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import { Helmet } from 'react-helmet'
import { POKER_HINDI } from '../../components/internal-links'
import SEO from '../../components/seo'
import './index.scss'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generatePokerFaqSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generatePokerWebPageSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Container } from 'react-bootstrap'
import loadable from '@loadable/component'

const PokerPlayerDetails = loadable(
  () =>
    import(
      '../../components/Design2.0/PokerPlayer/PokerPlayerDetails/PokerPlayerDetails'
    )
)

const OurBlogs = loadable(
  () => import('../../components/Design2.0/common/OurBlogs/OurBlogs')
)
const HomeFAQs = loadable(
  () => import('../../components/Index/HomeFAQs/HomeFAQs')
)
const Layout = loadable(() => import('../../components/layout'))
const PlayerContent = loadable(
  () =>
    import('../../components/Design2.0/PokerPlayer/PlayerContent/PlayerContent')
)

const BannerCarousel = loadable(
  () =>
    import(
      '../../components/Design2.0/HomePage/Features/Carousel/BannerCarousel'
    )
)
const PokerPlayer = (context: any) => {
  const bgColor = '#131313'
  const { data } = context
  const playerDetails = data.playerDetails

  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: POKER_BREADCRUMB_TITLE,
      url: POKER,
    },
    {
      title: 'Poker Players',
      url: '/poker/learn/20-best-poker-players-you-must-know-about/',
    },
    {
      title: playerDetails.name,
      url: '',
    },
  ]

  const SITE_URL = data.site.siteMetadata.siteUrl

  const metaTitle =
    playerDetails.name + ' | ' + 'Poker Player All Details | Mega Poker'
  const metaDescription = `${playerDetails.name}, Legendary Poker Player, brings you the latest insights, strategies, and highlights on Mega Poker. Discover tips, career achievements, and much more about ${playerDetails.name}`

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POKER}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
      customLogo
      pageName="/poker"
      backgroundColor={bgColor}
      langPost={[
        {
          languageName: 'english',
          pageSlug: `${SITE_URL}${POKER}`,
        },
        {
          languageName: 'hindi',
          pageSlug: `${SITE_URL}${POKER_HINDI}`,
        },
      ]}
      popularSearchLinksBg="#1d1d22"
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
        <link rel="alternate" href={`${SITE_URL}${POKER}`} hrefLang="en-in" />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER}`}
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_HINDI}`}
          hrefLang="hi-in"
        />
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription}
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generatePokerFaqSchema()}
        webPageSchema={generatePokerWebPageSchema()}
        hideHrefLang
      />

      <div
        style={{
          background: '#131313',
        }}
      >
        <div className="bg-gradient" id="details-container">
          <Container>
            <BannerCarousel
              imageWrapperPadding="0"
              containerStyle={{
                margin: '30px 0',
              }}
            />
          </Container>

          <PokerPlayerDetails {...playerDetails} />

          <PlayerContent {...playerDetails} />
        </div>
        <div className="d-none d-lg-block">
          <OurBlogs />
        </div>
        <div className="faq-container">
          <HomeFAQs
            backgroundColor={bgColor}
            containerStyles={{
              paddingBottom: '40px',
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default PokerPlayer

export const postQuery = graphql`
  query ($slug: String!) {
    playerDetails: googleSpreadsheetPokerPlayers(slug: { eq: $slug }) {
      about
      age
      dateOfBirth
      gpiRank
      height
      instagram
      networth
      name
      nationality
      slug
      tags
      totalLiveEarnings
      twitter
      wsopBracelets
      background
      pokerHistory
      profileImage
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
